import { IDENTITY_CONFIG, METADATA_OIDC } from "../app/authConst";
import { UserManager, Log, WebStorageStateStore, User } from "oidc-client";
import { StorageAuthStateStore } from "../app/storageAuthStateStore";
import store from "../app/store";

export default class AuthService {
    UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new StorageAuthStateStore(),
            stateStore: new WebStorageStateStore(),
            metadata: {
                ...METADATA_OIDC
            }
        });

        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;

        this.UserManager.events.addUserSignedOut(() => {
            console.log('User signed out from Identity Server.');
            this.UserManager.signoutRedirect(); // Opsional: Redirect ke halaman logout
        });

        this.UserManager.events.addUserSessionChanged(() => {
            console.log('Session status changed.');
            this.UserManager.getUser().then((user) => {
                console.log('Updated user session:', user);
            });
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(
            (user) => {
                window.history.replaceState(
                    {},
                    window.document.title,
                    window.location.origin
                );
                this.navigateToScreen();
            },
            (err) => {
                console.error(err);
                alert('Error caught in signinRedirectCallback()');
            }
        );
    };

    signinRedirect = () => {
        setTimeout(function () {
            // alert('GAS');
        }, 3000);
        localStorage.setItem("redirectUri", window.location.pathname);
        // this.UserManager.signinRedirect({});
        this.UserManager.signinRedirect().then((user) => {
            if (user) {
                alert('User is logged in via SSO:', user);
            } else {
                setTimeout(function () {
                    // alert('GAS DEUI');
                }, 3000);
                //alert('No SSO session found.');
            }
        }).catch((err) => {
            alert('Silent login failed:', err);
        });
    };

    navigateToScreen = () => {
        window.location.replace(localStorage.getItem("redirectUri"));
    };

    isAuthenticated = async () => {
        // const user = await this.UserManager.getUser();

        // if(user == null)
        // {
        //     await this.UserManager.signinSilent().then((user) => {
        //         if (user) {
        //             alert('User is logged in via SSO:', user);
        //         } else {
        //             alert('No SSO session found.');
        //         }
        //     }).catch((err) => {
        //         console.log(err)
        //         alert('Silent login failed:', err);
        //     });
        // }

        const userState = store.getState().oidc.oidc_user;
        return !!userState;
    };

    signinSilent = async () => {
        await this.UserManager.signinSilent().then((user) => {
            if (user) {
                console.log('User is logged in via SSO:', user);
            } else {
                console.log('No SSO session found.');
            }
            return user;
        }).catch((err) => {
            console.error('Silent login failed:', err);
        });
    };

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    logout = async () => {
        localStorage.clear();
        await this.UserManager.clearStaleState();
        await this.UserManager.removeUser();
        await this.UserManager.signoutRedirect();
        setTimeout(function () {
            // alert('GAS');
        }, 3000);
    };

    signoutRedirectCallback = async () => {
        alert('callback logout')
        await this.UserManager.signoutRedirectCallback().then(() => {
            alert('sukses callback logout')
            window.location.replace('/');
        });
    };
}
