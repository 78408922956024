import React, {Component} from "react";
import AuthService from "../services/authService";
import store from "../app/store";

export const AuthContext = React.createContext({
    signinRedirectCallback: async () => ({}),
    logout: async () => ({}),
    signoutRedirectCallback: async () => ({}),
    isAuthenticated: async () => ({}),
    signinRedirect: async () => ({}),
    signinSilentCallback: async () => ({}),
    createSigninRequest: () => ({})
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
    authService;
    constructor(props) {
        super(props);
        this.authService = new AuthService(store);
    }
    render() {
        return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
    }
}

export const useAuthContext = () => React.useContext(AuthContext);
